import {observable, action, computed} from "mobx";
import {ListView} from "antd-mobile";
import EnrollServers from "../../services/EnrollServers";
import Config from "../../config";
import WxApi from "../../common/wxapi";

class EnrollStore {
    @observable detail;
    @observable teamDetail;
    @observable isEnd;
    @observable isTeam;
    @observable countDown;
    @observable describeShow;
    @observable enrollUserList;
    @observable userListCurLt;
    @observable userListNumCount;
    @observable singleListNumCount;
    @observable refundTotal;
    @observable userListLoading;
    @observable currentTab;
    @observable isteamBuy;
    @observable istoJoin;
    @observable fastJoin;
	@observable messageList;
	@observable userInfo;
	@observable authInfo;
	@observable pcPayResult;
	@observable msgCount;
	@observable msgType;
	@observable currMessageIndex;
    ds = new ListView.DataSource({rowHasChanged: (r1, r2) => r1 !== r2});

    constructor() {
        this.detail = {};
        this.teamDetail = {};
        this.isEnd = 0;
        this.isStart = 0;
        this.isTeam = 1;
        this.describeShow = true;
        this.enrollUserList = [];
        this.userListCurLt = 0;
        this.userListNumCount = -1;
        this.singleListNumCount = -1;
        this.refundTotal = 0;
        this.userListLoading = false;
        this.userHasMore = true;
        this.currentTab = 0;
        this.isteamBuy = 1;
        this.teamId = null;
        this.fastJoin = {};
		this.messageList = [];
		this.userInfo = {};
		this.authInfo = {};
		this.pcPayResult = {};
		this.msgCount = 0;
		this.msgType = 0;
		this.currMessageIndex = -1;
        this.currMenu = 2
    }

    @computed get userDataSource() {
        return this.ds.cloneWithRows(this.enrollUserList.slice());
    }
	
	@computed get messageDataSource() {
	    return this.ds.cloneWithRows(this.messageList.slice());
	}

    @action
    restUserListPage = (type) => {
        this.userHasMore = true;
        this.userListCurLt = 0;
        this.enrollUserList = [];
        if(type == 'group') this.getTeamList();
        else this.getEnrollUserList();
    };
    @action
    initUserList(){
        this.userHasMore = true;
        this.userListLoading = false;
        this.userListCurLt = 0;
        this.enrollUserList = [];
    };
    @action
    setUserHasMore = (v) => {
        this.userHasMore = v;
    };

    @action
    setUserListLoading = (v) => {
        this.userListLoading = v;
    };

	@action
	restMessageListPage = (v) => {
		this.userHasMore = true;
		this.userListCurLt = 0;
		Config.pageCount = 1;
		this.messageList = [];
		this.msgType = v;
	};
	
	@action
	restMessageIndex = (v) => {
		this.currMessageIndex = v;
	};

    @action
    setTeam = (v) => {
        this.isTeam = v;
    };

    @action
    setCurrentTab = (v) => {
        this.currentTab = v;
        this.enrollUserList = [];
        this.userListCurLt = 0;
        Config.pageCount = 1;
        this.userHasMore = true;
    };

    @action
    setUserListCurLt = (v) => {
        this.userListCurLt = v;
    };

    @action
    setUserListNumCount = (v) => {
        this.userListNumCount = v;
    };
    
    @action
    setSingleListNumCount = (v) => {
        this.singleListNumCount = v;
    };

    @action
    setRefundTotal = (v) => {
        this.refundTotal = v;
    };
    
    @action
    setFastJoin = (v) => {
    	let _this = this;
    	this.fastJoin = v;
    };
    
    @action
    setDetail = (detailData) => {
        this.detail = detailData;
    };
    
	
    @action
    setTeamResultDetail = (teamDetail) => {
        this.teamDetail = teamDetail;
    };
    
    @action
    setUserInfo = (userInfo) => {
        this.userInfo = userInfo;
    };
	
	@action
	setAuthInfo = (authInfo) => {
	    this.authInfo = authInfo;
	};
	
	@action
	setPcPayResult = (result) => {
	    this.pcPayResult = result;
	};

    @action
    setCountDown = (countDown) => {
        this.countDown = countDown;
    };

    @action
    setIsEnd = (isEnd) => {
        this.isEnd = isEnd;
    };

    @action
    setIsStart = (isStart) =>{
        this.isStart = isStart;
    }
    @action
    setUserList = (list) => {
        if (this.userListCurLt == 0) this.enrollUserList = list;
        else this.enrollUserList = this.enrollUserList.concat(list);
    };
	
	@action
	setMessageList = (list) => {
	    if (this.userListCurLt == 0) {
			this.messageList = list;
		}else{
			this.messageList = this.messageList.concat(list);
		} 
		
	};


    //详情页面切换显示
    @action
    taggleDescribe = () => {
        this.describeShow = !this.describeShow;
    };
    
    @action
    setIsteamBuy = (isteamBuy) => {
        this.isteamBuy = isteamBuy;
    };
	@action
    setTeamId = (teamId) => {
        this.teamId = teamId;
    };
	
	@action
	setMsgCount = (v) => {
	    this.msgCount = v;
	};

    @computed get statusText() {
        let result = [];
        switch (this.detail.status) {
            case 0:
                result.push("未开始") ;
                break;
            case 1:
				result.push("进行中") ;
                // result += "进行中";
                break;
            case 2:
				result.push("已截止") ;
                // result += "已截止";
                break;
        }
        // if (this.detail.info_id) {
		// 	result.push("已提交");
        //     // result += " 已提交"
        // }

        return result;
    }

    @computed get tagClassName() {
        let result = "";
        switch (this.detail.status) {
            case 0:
                result = "";
                break;
            case 1:
                result = "tag-ing";
                break;
            case 2:
                result = "tag-end";
                break;
        }

        return result;
    }


    getEnrollDetailByEid() {
        let _this = this;
        return EnrollServers.getEnrollDetail().then((d) => {
            let result = EnrollServers.checkServerData(d, {});    
            let isAwait = sessionStorage.getItem('isAwait');          
            if(result.is_await == 1 && isAwait !=1){ //拥堵状态时,3s后再请求               
                return d;
            }    
            result.startTime = EnrollServers.formatDate(result.start_time * 1000, 'yyyy/MM/dd HH:mm','detail');
            result.endTime = EnrollServers.formatDate(result.end_time * 1000, 'yyyy/MM/dd HH:mm','detail');
            result.actStartTime = EnrollServers.formatDate(result.act_start * 1000, 'yyyy/MM/dd HH:mm','detail');
            result.actEndTime = EnrollServers.formatDate(result.act_end * 1000, 'yyyy/MM/dd HH:mm','detail');
            result.daily_time =  (result.daily_end - result.daily_start == 24 * 60 * 60 - 1) ? 0 : 1
            result.daily_start = result.daily_start?this.getTimeString(result.daily_start):'00:00';
			result.daily_end = result.daily_end?this.getTimeString(result.daily_end):'23:59';
            //如果包含Item
            if (result && result.items && result.items.length) {
				
				result.items = result.items.sort(_this.compare('seq'));
                result.items.map((item) => {
                    item.buy = 0;
                })
            }
            if ((!result.content) && result.pics && result.pics.length == 0) {
                result.content = "无";
            }
            if (result) {
                _this.setDetail(result);
            }

            return d
        });
    }

    // 获取项目详情
    getItemData(){
        return new Promise((resolve,rejct)=>{
            EnrollServers.getItemDetail('',this.detail.temp).then(d=>{
                let result = EnrollServers.checkServerData(d, {});
                if (result && result.items && result.items.length) {				
                    result.items = result.items.sort(this.compare('seq'));
                    result.items.map((item) => {
                        item.buy = 0;
                    })
                }
                this.detail.items = result.items                     
                this.setDetail(this.detail);
                resolve(d)
            })
        })
    }

    // 获取当前项目最近一个月的数据
    getCurrentItems(params){
        return new Promise((resolve,rejct)=>{
            let arrList = [5,8,10]
            if(arrList.indexOf(this.detail.temp) !== -1){
                EnrollServers.getItemDetail(params, this.detail.temp).then(d=>{
                    resolve(d)
                })
            }else{
                resolve()
            }    
        })
    }

    getTimeString(timeInSeconds) {
        var hour = Math.floor(timeInSeconds / 60 / 60);
        if (hour < 10) {
          hour = '0' + hour;
        }
        var minute = Math.floor((timeInSeconds - hour * 60 * 60) / 60);
        if (minute < 10) {
          minute = '0' + minute;
        }
        return hour + ':' + minute;
      }

	compare(property){
		return function(a,b) {
		      var value1 = a[property];
		      var value2 = b[property];
		      return value1 - value2
		}
	}

    getEnrollUserList() {
		// if(!this.detail.count || this.detail.count < 100){
		// 	return;
		// }
        if (!this.userHasMore || this.userListLoading) {
            return;
        }
        let _this = this;
        
        var lt = this.userListCurLt;
        if (lt == -1) return;
        _this.setUserListLoading(true);
        EnrollServers.getEnrollUserList(lt).then(async(d) => {
            _this.setUserListLoading(false);
            let result = EnrollServers.checkServerData(d, []);
			let _total = result.total + result.q_count;
			
            _this.setUserListNumCount(_total);
            if (result.items && result.items.length > 0) {
                let item = result.items;
                if (item.length > 9) lt = item[item.length - 1].info_id;
                else lt = -1;
                for(let a = 0; a < item.length; a++){
                    item[a].head_img = await WxApi.checkImage(item[a].head_img); 
                    item[a].date_str = EnrollServers.formatDate(item[a].date * 1000, 'yyyy-MM-dd HH:mm');  
                }                
                _this.setUserListCurLt(lt);
                _this.setUserList(item);

            }
            else {
                this.setUserHasMore(false)
            }

        });
    }

    getTeamList() {
        if (!this.userHasMore || this.userListLoading) {
            return;
        }
        let _this = this;
		 var lt = this.userListCurLt;
        if (lt == -1) return;
        var team = this.isTeam, page = Config.pageCount;
        _this.setUserListLoading(true);
        EnrollServers.getTeamList(team).then(async (d) => {
            _this.setUserListLoading(false);
            let result = EnrollServers.checkServerData(d, []);
            _this.setUserListNumCount(result.team_total);
            _this.setSingleListNumCount(result.single_total);
            _this.setRefundTotal(result.refund_total);
            if (result.items && result.items.length > 0) {
                let item = result.items;
                if(page ==1){
                	if(item.length>=2){
                		let fastArr = item.slice(0,2);
                		fastArr.map(function(obj,index){
                			if(obj.team_num == obj.joins){
                				fastArr.splice(index,1)
                			}
                		})
                		sessionStorage.setItem("fastArr",JSON.stringify(fastArr));
                	}else if(item.length == 1){
                		let fastArr = item.slice(0,1);
                		if(fastArr[0].team_num == fastArr[0].joins){
                			sessionStorage.removeItem("fastArr");
                		}else{
                			sessionStorage.setItem("fastArr",JSON.stringify(fastArr));
                		}
                		
                	}else{
                		sessionStorage.removeItem("fastArr");
                	}
                }
                
                if (item.length > 9){
                	page = page + 1;
                	lt = page;
                } 
                else {
                	page = 1;
                	lt = -1;
                }

                for(let a = 0; a < item.length; a++){
                    item[a].head_img = await WxApi.checkImage(item[a].head_img); 
                    item[a].date_str = EnrollServers.formatDate(item[a].date * 1000, 'yyyy-MM-dd HH:mm');
                }                

                Config.pageCount = page;
                _this.setUserListCurLt(lt);
                _this.setUserList(item);

            }
            else {
            	sessionStorage.removeItem("fastArr");
                this.setUserHasMore(false)
            }

        });
    }
	
	//获取留言列表
	getMessageList(){
		if (!this.userHasMore || this.userListLoading) {
		    return;
		}
		let _this = this;
		var lt = this.userListCurLt;
		var page = Config.pageCount;
		if (lt == -1) return;
		return EnrollServers.getMessageList(this.msgType).then((d) => {
			let result = EnrollServers.checkServerData(d, []);
			_this.setMsgCount(result.user_msg_count);
			if (result.msg_list && result.msg_list.length > 0) {
			    let item = result.msg_list;
				
				if (item.length > 9){
					page = page + 1;
					lt = page;
				} 
				else {
					page = 1;
					lt = -1;
				}
				Config.pageCount = page;
				// let newItem = [];
				item.map((item) => {
				    item.date = EnrollServers.formatDate(item.date * 1000, 'yyyy-MM-dd HH:mm');
					/* if(item.fav || item.top){
						newItem.push(item)
					} */
				});
				
				if (item.length > 9) lt = item[item.length - 1].msg_id;
				else lt = -1;
				
				_this.setUserListCurLt(lt);
				let uId = sessionStorage.getItem('unionid');
				item = item.filter(obj => obj.fav ==1 || uId ==obj.unionid || this.detail.is_owner || this.detail.is_admin);
			    _this.setMessageList(item);
			
			}
			else {
			    this.setUserHasMore(false)
			}
		});
	}
    
	//删除留言
	deleteMessage(msg_id) {
		 return EnrollServers.deleteMessage(msg_id).then((d) => {
			let result = EnrollServers.checkServerData(d, {});
			if(result){
				if(this.currMessageIndex > -1){
					 this.messageList.splice(this.currMessageIndex , 1);
				}
			}
	        // return EnrollServers.checkSuccess(d);
	    })
	}
	
    getTeamDetail(){
    	 let _this = this;
    	return EnrollServers.getTeamDetail(this.teamId).then((d) => {
    		let result = EnrollServers.checkServerData(d, []);
    		if(result){
    			this.setTeamResultDetail(result)
    			result.endTime = EnrollServers.formatDate(result.end_time * 1000, 'MM/dd HH:mm');
    			 // 生成倒计时
	            let endTime = result.end_time, startTime = result.start_time,now = new Date().getTime() / 1000;
	            // 先清除倒计时，避免多次进入详情页后，生成多个倒计时
	            clearInterval(window.timer);
                var diffStartTime = startTime - now;
	            var diffEndtimes = endTime - now;
	            _this.setGroupCountDown(diffStartTime,diffEndtimes);
    			
    		}
    		
            return EnrollServers.checkSuccess(d);
    	});
    }

    getShare(type) {
        return EnrollServers.getShare(type).then((d) => {
            return EnrollServers.checkServerData(d, {});
        });
    }

    getHomePageShare(unionid) {
        return EnrollServers.getHomePageShare(unionid).then((d) => {
            return EnrollServers.checkServerData(d, {});
        });
    }

    subscribeHomepage(type, unionid) {
        return EnrollServers.subscribeHomepage(type, unionid).then((d) => {
            return d.data;
        });
    }

	setCollect(type) {
        return EnrollServers.setCollect(type).then((d) => {
            return EnrollServers.checkServerData(d, {});
        });
    }

    getGroupDetailByEid() {
        let _this = this;
        return EnrollServers.getEnrollDetail('group').then((d) => {
            let result = EnrollServers.checkServerData(d, {});
            result.startTime = EnrollServers.formatDate(result.start_time * 1000, 'yyyy/MM/dd HH:mm');
            result.endTime = EnrollServers.formatDate(result.end_time * 1000, 'MM/dd HH:mm');
            result.actStartTime = EnrollServers.formatDate(result.act_start * 1000, 'yyyy/MM/dd HH:mm', 'detail');
            result.actEndTime = EnrollServers.formatDate(result.act_end * 1000, 'MM/dd HH:mm');

            //如果包含Item
            if (result && result.items && result.items.length) {
                result.items.map((item) => {
                    item.buy = 0;
                })
            }
            if ((!result.content) && result.pics && result.pics.length == 0) {
                result.content = "无";
            }

            if (result) {
                _this.setDetail(result);
                _this.setIsEnd(0);
                _this.setIsStart(0);    
            }
           
            return d
        });
    }

    setGroupCountDown(diffStartTime,diffEndtimes) {    
        var self = this;
        let times = 0,timeType = 3; //已结束
        if(diffStartTime > 0){ //未开始
            timeType = 1;
            times = diffStartTime;
        }else if(diffEndtimes > 0){ //进行中
            times = diffEndtimes;
            timeType = 2
            self.setIsStart(1)
        }
        window.timer = setInterval(function () {
            var day = 0,
                hour = 0,
                minute = 0,
                second = 0; //时间默认值
            if (times > 0) {
                day = Math.floor(times / (60 * 60 * 24));
                hour = Math.floor(times / (60 * 60)) - (day * 24);
                minute = Math.floor(times / 60) - (day * 24 * 60) - (hour * 60);
                second = Math.floor(times) - (day * 24 * 60 * 60) - (hour * 60 * 60) - (minute * 60);
            }
            if (day <= 9) day = '0' + day;
            if (hour <= 9) hour = '0' + hour;
            if (minute <= 9) minute = '0' + minute;
            if (second <= 9) second = '0' + second;       
            var rs = "剩余时间 " + day + " 天 " + hour + " : " + minute + " : " + second;                     
            self.setCountDown(rs);   
            times--;
        }, 1000);

        if (times <= 0 && (timeType == 2 || timeType == 3)) {
            self.setCountDown(0);
            self.setIsEnd(1);
            clearInterval(window.timer);
        }else if(times <= 0 && timeType == 1){
            clearInterval(window.timer);
            self.setIsStart(1)
            self.getGroupDetailByEid()
        }
       
    }
	
	getUserInfo(){
		let _this = this;
		return EnrollServers.getUserInfo().then(async(d) => {
			let result = EnrollServers.checkServerData(d, {});
			if (result) {
				result.expire = EnrollServers.formatDate(result.expire * 1000, 'yyyy-MM-dd');
                let upic = localStorage.getItem('upic')
                result.logo = await WxApi.checkImage(result.logo);                                
				if(!result.logo && upic){
                    result.logo = upic
                }
			    _this.setUserInfo(result);
			}
		})
	}
	
	getAuthInfo(renew){
		let _this = this;
		return EnrollServers.getAuthInfo(renew).then((d) => {
			let result = EnrollServers.checkServerData(d, {});
			if (result) {
			    _this.setAuthInfo(result);
			}
		})
		
	}
	
	getAuthMsg(id){
        return EnrollServers.getAuthMsg(id).then((d)=>{
            return EnrollServers.checkServerData(d, {});
        })
    }

	//获取pc认证支付结果
	getPcPayResult(orderNo){
		let _this = this;
		return EnrollServers.getPcPayResult(orderNo).then((d) => {
			let result = EnrollServers.checkServerData(d, []);
			if(result){
				_this.setPcPayResult(result);
				
			}
		});
	}
	
    // 获取当前选中的菜单
    getCurrMenu(index){
        this.currMenu = index
    }

    // 详情填写字段接口
    getReqDetail(){
        return EnrollServers.getReqDetail().then((d)=>{                 
            if(d.data.sta == 0 && d.data.data && d.data.data.req_info){
                this.detail.req_info = d.data.data.req_info;         
                this.detail.req_logic = d.data.data.req_logic;   
                this.setDetail(this.detail);
            }                 
            return EnrollServers.checkServerData(d, {});
        })
    }
}


const enrollStore = new EnrollStore();

export {enrollStore};
