//外部组件引入
import React, {Component, Fragment} from 'react';
import {Flex, Button, PullToRefresh, List, Badge,Toast,Modal} from 'antd-mobile';
import {inject, observer} from 'mobx-react';
import EnrollServers from '../../services/EnrollServers';
//自定义内容引入
import UserList from "./userList";
import Config from "../../config";
import WxApi from "../../common/wxapi";
import NewMsgDialog from '../../components/NewMsgDialog';
import SetUserInfo from '../../components/SetUserInfo';
import ManageDialog from '../../components/ManageDialog';
//图片引入
import img_see from "../../res/img/group_only.png";
import img_pintuan from "../../res/img/ic_pintuan_time.png";
import img_identification_p from "../../res/img/ic_identification_person_small.png";//个人认证
import img_identification_c from "../../res/img/ic_identification_company_small.png";//企业认证
import img_identification_o from "../../res/img/ic_identification_organization_small.png";//组织认证
import img_identification_g from "../../res/img/ic_identification_gzh_small.png";//公众号认证
import wxapi from "../../common/wxapi";
// import img_wechat_com from "../../res/img/ic_detail_wechat.png";
// import img_phone_com from "../../res/img/ic_detail_phone.png";
import img_qun from "../../res/img/ic_detail_group_photo.png"
import img_arrow_down from "../../res/img/ic_arrow_up.png";
import img_code_home from "../../res/img/ic_code_homepage.png";
import img_mask_arrow from "../../res/img/ic_mask_arrow.png";
import img_pintuan_tips from "../../res/img/pic_pintuan.png";
import right_arrow from "../../res/img/right_arrow.png";
import ic_bar_create from "../../res/img/ic_bar_create.png";
import ic_person_identity from "../../res/img/ic_person_identity_V.png";
import is_follow from "../../res/img/ic_detail_follow_done.png";
import no_follow from "../../res/img/ic_detail_follow.png";
import img_subscription from "../../res/img/ic_detail_notice_guide.png"
import left_arrow from "../../res/img/icon_left_arrow.png";

import ic_top_row from "../../res/img/ic_detail_top_arrow.png"
import ic_top_notice from "../../res/img/ic_detail_top_notice.png"
import ic_conct_btn from "../../res/img/ic_conct_btn.png"
import icon_right_img from '../../res/img/right_arrow_blue.png'
import icon_create_enroll from '../../res/img/icon_create_enroll.png'
import icon_adress from '../../res/img/icon_adress.png'
import icon_video_img from '../../res/img/big-num-video.png'
import icon_close_img from '../../res/img/ic_popup_close.png'
import SubmitNotice from '../../components/SubmitNotice';
const Item = List.Item;
const Brief = Item.Brief;


@inject("enrollStore", "loadingStore", "myEnrollStore")
@observer
class App extends Component {

    state = {
        height: 0,
        sharePanel:'none',//分享选择界面
		showDetailQrCode:'none',
		showHomePageQrCode:'none',
		shareFriend:'none',
		roleMinProgram:'none',//认证小程序二维码
		tokenPanel:'none',//口令输入框
		heightAuto:false,//图片是否根据高度自适应
		rendBanner:true,
		isWeixin:true,//判断是否在微信环境
		hasActive:true,//这个活动是否存在
		img_code_home:'',
		loginTimer:'',
		groupDialog:false,
		btnTypeText:["立即报名", "点击填写", "立即预约", "了解更多", "下一步","立即购买",'立即申请','立即购买'],
		showWxlink:false,
		censorModal:false,
		showEnrollToken:false,
		enroll_token:'',
		groupList: [],
		show_notice: false,
		vipList: [],
		isVertical: false,
		showManage: false,
		timeInfo: {
			hour: 0,
			minute: 0,
			second: 0,
		}
    };

	renderHeader(detail) {
		const miniBtnStyle = {
			width: 'calc(100vw - 65px)',
		    height:'40px',
		    background:'#f7f7f7',
			color: '#666666',
			fontSize:12,
			textAlign:'center',
		   }

		let signName = detail.sign_name;
		if(detail.role && detail.auth == 1 && !detail.cert_show){
			signName = detail.sign_name.substr(0,1) + "**";
		}

		return(<div className="d-header">
			<div className="d-header-my" onClick={()=>{
				this.props.enrollStore.getCurrMenu(2)
				window._hmt.push(['_trackPageview',  '/manage/myEnroll'])
				this.props.history.push('/manage/myEnroll') 
			}}>
				<img className="left-arrow" src={left_arrow} />
				<span>我的</span>
			</div>
			<div className="d-header-left cursor" onClick={()=>{
				window._hmt.push(['_trackEvent', 'button_to_wx','', '活动详情顶部点击打开小程序按钮', '']); //百度统计
				if((!WxApi.isMobile() || WxApi.isOtherEnvironments()) && !WxApi.isWeixn()){ //pc浏览器					
					this.showDetailQrCode('block')
				}else if(WxApi.isWeixn() && !Config.TOKEN){
					React.$wx.webLogin();
				}else if(!WxApi.isWeixn() && WxApi.isMobile() && !wxapi.isUnionPay()){//手机浏览器				
					this.getTicket(detail)
				}else if(WxApi.isWeixn() && !WxApi.isMobile()){
					this.props.history.push('/h/'+ detail.owner_unionid)
				}	
			}}>
				<img src={detail.owner_pic||Config.defaultAvatar} />
				<h4 className="user-name">{signName}</h4>	
				<button className="btn-open-mini" style={{backgroundColor: this.state.themColor}}>去看看</button>
			</div>
			{
				WxApi.isWeixn() && WxApi.isMobile() ? <div className="btn-open-mini-opacity">
					<wx-open-launch-weapp
						style={miniBtnStyle}
						id="launch-btn"
						username="gh_ae6449501a82"
						path={detail.eid ? this.wxJumpPath : '/pages/default/default.html'}
						>
						<script type="text/wxtag-template">
							<div style={miniBtnStyle}>
								<div>去看看</div>
							</div>
						</script>
					</wx-open-launch-weapp>
				</div> : ''
			}
			</div>);
	}

    getDetail() {
        if (Config.EID) {

            let _this = this;
            this.props.enrollStore.getGroupDetailByEid().then(async (res) => {
                let {detail} = _this.props.enrollStore;
				detail.owner_pic = await wxapi.checkImage(detail.owner_pic);			 
				let themColor = '#00a8f3';
				if(detail && detail.btn_config && detail.btn_config.color){
					themColor = detail.btn_config.color;
				}		 
				let vipList = [];
				if(detail.user_owner_vip_all && detail.user_owner_vip_all.length){
					vipList = detail.user_owner_vip_all.filter(vip => vip.expire > new Date().getTime() / 1000).map(vip => vip.card_id); //获取当前用户有哪些没过期的会员卡
				}
				_this.setState({
					sub_user: detail.sub_user,
					detail: detail,
					hasActive:res.data.sta == -1?false:true,
					themColor,
					vipList: vipList,
				})
                
                //订阅消息回调
				let sceneId = WxApi.getQueryString("scene");
				let openid = WxApi.getQueryString("openid");
				let notice = sessionStorage.getItem("notice");
				if(sceneId && openid && notice =='notice'){
					_this.receiveNotice(sceneId,openid)
				}
                		
				if(detail.btn_config && detail.btn_config.btn_cd == 1){ //开启了倒计时
					this.getTimeDown(detail);
				}

				if(detail.sub_user_setting && detail.sub_user_setting.indexOf('group_enter') != -1){ //指定群聊可参与		
					if((detail.is_owner || detail.is_admin)){
						this.initShareGroup(detail);
					}else if(!detail.info_id){
						this.props.history.push('/error/5')
					}
				}
				  

                if(detail.title){
					document.title = detail.title
	                sessionStorage.setItem("authorizer_appid",detail.authorizer_appid);
	                if(!Config.TOKEN){
						let isWeixin = WxApi.isWeixn();
						let isMobile = WxApi.isMobile();
						const code = WxApi.getQueryString("code");
						const isOldUser = localStorage.getItem('isOldUser'); //微信曾经授权过报名工具的用户
						if(!isWeixin){
							_this.setState({
								isWeixin:isWeixin,
								isMobile:isMobile
							})
						}else if(code || isOldUser){//微信收授权后，则重新登录
							WxApi.jumpWxAuth();
						}
	                }else{
						_this.getNoticeInfo(detail);  //顶部展示通知	
	                }
                }
                
                if(detail.unionid){
                	sessionStorage.setItem('unionid',detail.unionid);
					sessionStorage.setItem('owner_unionid',detail.owner_unionid);
                }
       
                
                let shareText = "";
				if(detail.auth > 1){
					shareText = detail.sign_name;
				}else if(detail.auth == 1){
					shareText = "<点击查看内容>"
				}else{
					shareText = "来自报名工具小程序"
				}
				
				if(detail.content && Array.isArray(detail.content)){
					let index = detail.content.findIndex(item=>item.type === 'text'&&item.value)
					if(index>-1){
						shareText = detail.content[index].value
					}
				}
				await this.toEditorMsg(detail)
				this.wxJumpPath = `/groupbuy/pages/groupbuy/detail.html?eid=${detail.eid }&referer=${Config.referer}`
                let _url = wxapi.replaceParamVal('referer',Config.referer) + "/group";
				wxapi.setShare(detail.title, shareText, _url, detail.banner || Config.LOGO);
                if(detail.auth == 0){
					if(wxapi.isWeixn()){ //这个是为了避免在ios中注册微信api与初始进入页面不一致，导致微信api不生效
						const checkWxApi = () => {
							return new Promise((resolve, reject) => {
							  let interval = setInterval(() => {
								if (typeof window.wx !== 'undefined' && window.wx && window.wx.openBusinessView) {
									clearInterval(interval);
									resolve();
								}
							  }, 200);
							});
						  }						  
						checkWxApi().then(() => {
							this.props.history.replace('/share?eid='+detail.eid+'&referer='+Config.referer);
						});
					}else{
						this.props.history.replace('/share?eid='+detail.eid+'&referer='+Config.referer);	
					}	
				}else if(detail.censor_status==2){
                    this.props.history.push('/error/2')
                }else if(detail.censor_status == 3){
					this.setState({ censorModal: true });			 
				}

				if(detail.banner){
					//根据短边来裁剪banner图片
					let _img  = new Image();
					_img.src = detail.banner;
					_img.onload = function(detail){					 
						if(_img.height >= _img.width * 1.5){
							_this.setState({isVertical: true});
						}				 
					}
					_img.onerror = function(err){
						detail.banner = null;
					}
				}else{
					detail.banner = '';
				}				
            }, () => {
                this.props.history.push('/page-404')
            });
            this.props.enrollStore.restUserListPage('group');

        }
        else {
            this.props.history.push('/page-404')
        }

    }
	// 如果是发起人编辑参与者报名信息，则直接跳转
	toEditorMsg(detail){
		if(!detail || !detail.title)return
		return new Promise((resolve, reject)=>{
			let info_id = WxApi.getQueryString('info_id')
			let verified = WxApi.getQueryString('verified')
			let token = WxApi.getQueryString("tk")
			if(info_id && verified){		
				if(token && !WxApi.isWeixn()){
					localStorage.setItem(Config.wx_token_key,token)			
				}	
				sessionStorage.setItem('fromPC',1)//从pc过来编辑报名信息			
				this.props.enrollStore.getItemData().then(()=>{					
					this.props.myEnrollStore.setCurrentEnroll({info_id,verified})
					this.props.history.push('/enrollGroupForm?eid='+ Config.EID)
					reject()
				})					
			}else if(WxApi.getQueryString('eid') && WxApi.getQueryString('id')){ //打印pdf上的二维码扫描进入这里	
				info_id = WxApi.getQueryString('id')	 
				if(Config.TOKEN){
					this.props.enrollStore.getItemData().then(()=>{					
						this.props.myEnrollStore.setCurrentEnroll({info_id})
						this.props.history.push('/enrollGroupForm?eid='+ Config.EID)
						reject()
					})		
				}else{
					React.$wx.webLogin();
				}							
			}else{
				resolve()
			}		
		})
		
	}
    // 从普通浏览器跳转到微信小程序
	getTicket(detail){
		this.props.myEnrollStore.getShortTicket({eid:detail.eid}).then(res=>{
			if(res.sta == 0){
				window.location.href = res.data.scheme_ticket;
			}
		})
	}
    initShareGroup(detail){
		this.props.myEnrollStore.getShareGroup(detail.eid).then(res=>{
			if(res.sta == 0){				 
				this.setState({groupList: res.data.open_group});
			}
		})		
	}
    //订阅通知
	receiveNotice(sceneId,openid){
		this.props.myEnrollStore.subNotice(sceneId,openid).then(() => {
			let {orderStatus} = this.props.myEnrollStore;
			if(orderStatus){
				sessionStorage.removeItem("notice");
				Toast.success('订阅成功!', 1);
			}
		});
	}
	
	//获取微信系统通知信息
	getNoticeInfo(detail){
		let _this = this;
		if(Config.TOKEN){
			this.props.myEnrollStore.getNoticeAll(detail.eid).then(() => {
				_this.renderNotice(detail)
				let showScene = (detail.follow.scene == 0 && detail.info_id) || (detail.follow.scene == 1 && detail.verify == 1 && detail.verified == 1) || (detail.follow.scene == 2 && detail.info_id || (detail.verify ==1 && detail.verified == -1));
				const {wxNotice} = this.props.myEnrollStore;
				let noticeArr = wxNotice.msg_list;
				let sms_list = wxNotice.sms_list;
				if(noticeArr && noticeArr.length > 0){
					noticeArr = noticeArr.filter(item => !item.time_out || item.time_out == 0);
				}
				let show_notice = false;
				if(noticeArr && noticeArr.length > 0 &&  noticeArr[0].content && (detail.info_id || (detail.period && detail.period_info))){
					show_notice = true;
				}else if(sms_list && sms_list.length > 0){
					show_notice = true;
				}else if(detail && detail.follow && detail.follow.type > 0 && (detail.follow.desc||detail.follow.qrcode||detail.follow.link_url) && showScene){
					show_notice = true;
				}
				this.setState({show_notice});
			});
		}
	}

    componentDidMount() {
        this.getDetail();
		sessionStorage.removeItem('teamReqList');
		sessionStorage.removeItem('teamPersonalMsg');	
		this.props.enrollStore.getShare(1).then((d) => { //右侧广告码
			if(d && d.url) {
				this.setState({
					detailQrCode: d.url,
				})
				this.props.myEnrollStore.setShareImg(d.url);
			}
		})		 
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.match.url !== nextProps.match.url) {
            this.getDetail();
        }
    }

	// 右侧广告位
	renderWxCode(){
		if(this.state.detailQrCode){
			return (
				<div className="advert-box-right">
					<div className="wx-code-box">
						<div className="wx-qrcode">
							<img src={this.state.detailQrCode}></img>
						</div>
						<span>微信扫一扫</span>
						<span className="wx-watch">打开小程序</span>
					</div>
					<a className="create-enroll-box" target="_blank" href='https://www.baominggongju.com/?hmsr=h5'>
						<img src={icon_create_enroll} className="icon-create"/>
						<span className="enroll-text">我也要创建活动</span>	
						<img src={icon_right_img} className="icon-right-blue"/>
					</a>
				</div>
			)
		}
	}
	// 指定群参加
	renderWxGroup(detail){
		const miniBtnStyle = {
			position: 'absolute',
			left: 0,
			top: 0,
			width: '100%',
			height: '40px',
		}
		return (
			<div class='fixed-group-team' style={{bottom: this.state.show_notice ? '90px' : '50px'}}>
				<div className='group-main' onClick={()=>{
					window._hmt.push(['_trackEvent', 'button_to_wx','', '活动详情顶部点击打开小程序按钮', '']); //百度统计				 
					if((!WxApi.isMobile() || WxApi.isOtherEnvironments()) && !WxApi.isWeixn()){ //pc浏览器							 	
						this.showDetailQrCode('block')
					}else if(WxApi.isWeixn() && !Config.TOKEN){					 
						React.$wx.webLogin();
					}else if(!WxApi.isWeixn() && WxApi.isMobile() && !wxapi.isUnionPay()){//手机浏览器							 
						this.getTicket(detail)
					}else if(WxApi.isWeixn() && !WxApi.isMobile()){					 
						this.props.history.push('/h/'+ detail.owner_unionid)
					}		
				}}>
					<div className='left-box'>
						<img className='msg-img' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20240221/d542a1fdb85c9cdbe73ec49383ae7924.png"></img>
						<span>{this.state.groupList && this.state.groupList.length > 0 ? this.state.groupList.length : 0}个指定群参加</span>
					</div>
					<span>设置 ></span>
					{
						WxApi.isMobile() && WxApi.isWeixn() ?
						<wx-open-launch-weapp
							id="launch-btn" style={miniBtnStyle}
							username="gh_ae6449501a82"
							path={detail.eid ? this.wxJumpPath : '/pages/default/default.html'}
							>
							<script type="text/wxtag-template">
								<div style={miniBtnStyle}>
									
								</div>
							</script>
						</wx-open-launch-weapp> : null
					}
				</div>
			</div>
		)
	}
	//自定义提交通知
	renderNotice(detail){
		const {wxNotice} = this.props.myEnrollStore;
		let noticeArr = wxNotice.msg_list;
		let sms_list = wxNotice.sms_list;
		if(noticeArr && noticeArr.length > 0){
			noticeArr = noticeArr.filter(item => !item.time_out || item.time_out == 0);
		}
		
		const miniBtnStyle = {
			width: '414px',
		    height:'40px',
		    background:'#f7f7f7',
		   }
		   
		if(noticeArr && noticeArr.length > 0 &&  noticeArr[0].content && detail.info_id){
			return(
				<div className="notice-body-panel">
					<div className="notice-box" onClick={() => {
							sessionStorage.setItem("noticeTab",0);
							this.props.history.push('/notice');
						}}>
						<img className='notice-icon' src={ic_top_notice} />
						<p className='notice-content'>{noticeArr[0].content}</p>
						<img className='icon-row' src={ic_top_row} />
					</div>
					{this.state.isWeixin ? <div className="notice-box-opacity">
					<wx-open-launch-weapp
						  id="launch-btn"
						  username="gh_ae6449501a82"
						  path={detail.eid ? this.wxJumpPath +'&open_type=notice' : '/pages/default/default.html'}
						>
						<script type="text/wxtag-template">
							 <div style={miniBtnStyle}>
								
							 </div>
						</script>
					</wx-open-launch-weapp>
				</div> : null}
				</div>
			)
		}else if(sms_list && sms_list.length > 0){
			return (
				<div className="notice-body-panel">
					<div className="notice-box" onClick={() => {
								sessionStorage.setItem("noticeTab",0);
								this.props.history.push('/notice');
							}}>
							<img className='notice-icon' src={ic_top_notice} />
							<p className='notice-content'>{sms_list[0].content}</p>
							<img className='icon-row' src={ic_top_row} />
						</div>
						{this.state.isWeixin ? <div className="notice-box-opacity">
						<wx-open-launch-weapp
							id="launch-btn"
							username="gh_ae6449501a82"
							path={detail.eid ? this.wxJumpPath + '&open_type=notice' : '/pages/default/default.html'}
							>
							<script type="text/wxtag-template">
								<div style={miniBtnStyle}>
									
								</div>
							</script>
						</wx-open-launch-weapp>
					</div> : null}
				</div>
			)
		}else if(detail && detail.follow && detail.follow.type > 0 && (detail.follow.desc||detail.follow.qrcode||detail.follow.link_url) && (detail.info_id || detail.on_behalf == 1)){
			return(
				<div className="notice-body-panel">
					<div className="notice-box" onClick={() => {
							sessionStorage.setItem("noticeTab",1);
							this.props.history.push('/notice');
							
						}}>
						<img className='notice-icon' src={ic_top_notice} />
						<p className='notice-content'>{ detail.follow.desc || '已参加活动的小伙伴，请点击查看'}</p>
						<img className='icon-row' src={ic_top_row} />
					</div>
					{this.state.isWeixin ? <div className="notice-box-opacity">
						<wx-open-launch-weapp
							  id="launch-btn"
							  username="gh_ae6449501a82"
							  path={detail.eid ? this.wxJumpPath + '&open_type=notice&notice_type=1' : '/pages/default/default.html'}
							>
							<script type="text/wxtag-template">
								 <div style={miniBtnStyle}>
									
								 </div>
							</script>
						</wx-open-launch-weapp>
					</div> : null}
				</div>
			)
		}
	}
	
	
    //banner图片
    renderBanner(detail) {
    	let imgHeight = document.body.offsetWidth * 0.6;
		let endTime = EnrollServers.formatDate(detail.end_time * 1000, 'yyyy-MM-dd HH:mm');
    	if(detail.banner) {
    		return (<Fragment>
	            <div className="Detail-header-image">
	            <img className='enroll-detail-img' onClick={() => {
	                React.$wx.previewImage({
	                    current: detail.banner, // 当前显示图片的http链接
	                    urls: [detail.banner] // 需要预览的图片http链接列表
	                });
	            }} src={WxApi.getAutoImage(detail.banner, 750, 'single')}/>
	            </div>
	        </Fragment>);    		
    	}else if(detail.video && detail.video.url){
    		return(<div className="Detail-header-image" style={{height: imgHeight}}>
				<video className="video-banner" src={detail.video.url} controls="controls" poster={detail.video.pic_url}></video>
			</div>);
    	}else if(detail.banner === null){
			return (<div className="Detail-header-image"><div className='enroll-detail-img'></div></div>)
		}
        
    }

    //活动内容
    renderContent1(detail) {
        if(detail.temp !=6)return
		let listItem = [
			{
				img: 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220411/354236005ef43fa149a8a80d09942f7b.png',
				isShow: !!detail.act_start,
				title: "活动：",
				content: detail.actStartTime + " - " + detail.actEndTime
			},
			{
				img: 'https://cdn-xcx-qunsou.weiyoubot.cn/material/20220411/5d5a06f07bf908516ba4af0f3c939764.png',
				isShow: !!detail.address,
				title: "地址：",
				content: detail.address, //TODO 这里需要添加定位,
				action: () => {
					if(!detail.address_type || detail.address_type ==0){
						if(WxApi.isMobile() && WxApi.isWeixn()){
							if(!Config.TOKEN){
								React.$wx.webLogin();
								return;
							}
							window.wx.openLocation({
								latitude: detail.latitude, // 纬度，浮点数，范围为90 ~ -90
								longitude: detail.longitude, // 经度，浮点数，范围为180 ~ -180。
								address: detail.address, // 地址详情说明
								scale: 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
							});
						}else{
							const linkElement = document.createElement('a');
							linkElement.target="_blank";
							linkElement.href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${detail.latitude},${detail.longitude};title:活动地址;addr:${detail.address}&referer=myapp`
							// linkElement.href = `https://api.map.baidu.com/marker?location=${detail.latitude},${detail.longitude}&title=活动地址&content=${detail.address}&output=html&src=地图&ak=G1RjyBHcccDk6pb865C4FrPXjcpB2eKM`						
							const clickEvent = new MouseEvent('click', {
								view: window,
								bubbles: true,
								cancelable: true
							});							
							linkElement.dispatchEvent(clickEvent);
						}						
					}else{
						this.setState({showLink:true});
					}
					
				}
			},
		]
        return (
            <div className="Detail-content1">
                <h1 className="Detail-title">
                    {detail.title}
                </h1>
                <Flex className="Detail-subtitle" align={"around"} style={{position: 'relative'}}>
				{
					detail.team_items ? <div>
						<span className='price-tuan'>￥{detail.team_items.team_fee/100}</span>
						<span className='price-single'>￥{detail.team_items.fee/100}</span>
						<span className='price-text'>{detail.team_items.team_num}人拼团</span>
					</div> : ''
				}               				
				{
					!detail.team_items && detail.items && detail.items.length ? <div>
						<span className='price-tuan'>￥{detail.items[0].team_fee/100}</span>
						<span className='price-single'>￥{detail.items[0].fee/100}</span>
						<span className='price-text'>{detail.items[0].team_num}人拼团</span>
					</div> : ''
				}              				
				<button className="detail-notice-btn" style={{color: this.state.themColor}} onClick={() => {
						if(detail.is_fans){					
							this.child.setDialog('open')
						}else{
							window.location.href = 'https://mp.weixin.qq.com/s/7xoT8N4jJuR36X3UoZEQVg'
						}
					}}>					
					<span>订阅&nbsp;</span>
					<i className="notice-icon iconfont icon-svg45-copy" style={{color: this.state.themColor}}></i>
				</button>
                </Flex>
 				<Flex className="Detail-content" direction={"column"} align={"start"} justify={"center"}>
					{
						listItem.map((item, index) => {
							if (item.isShow) {
								return (
									<Flex.Item key={index} className="Detail-content-text">
										<img src={item.img}/>
										<div className="Detail-content-right">
											{ !this.state.isVertical ? <span>{item.title}</span> : null }	                              
											<span className={item.action ? "action" : ""} onClick={() => {
												if (item.action) {
													item.action();
												}
											}}>{item.content}</span>
										</div>
									</Flex.Item>
								)
							}
						})
					}
				</Flex>
            </div>
        )
    }

	//设置认证按钮是否显示
	 getBtn(detail) {
        if(detail.auth ==0 && detail.is_owner){
            return (<div className="role-btn" onClick={() => { this.showRoleMinProgram('block')}}>
	            	<div className="left-panel">
	            		<i></i>
	            		<span>升级VIP后，享有更多权益</span>
	            	</div>
	            	<div className="right-panel">
	            		<span>去认证 > </span>
	            	</div>
	            </div>)
        }
    }
	 
	
 	getHeader(detail){
    	if(!detail.role || (detail.role && detail.auth ==1)){
    		return(<img className='min-img-header' src={detail.owner_pic||Config.defaultAvatar}/>)
    	}else{
    		return(<img src={detail.owner_pic||Config.defaultAvatar} />)
    	}
    	
    }

    //输出发起人相关信息
    renderOwner(detail) {
        let icon = null;
//      let text = detail.is_owner ? "待认证" : "";
		let text = "";
        let signName = detail.sign_name;
        if(detail.role){
        	switch (detail.auth) {
	            case 1:
					icon = img_identification_p;
					text = "个人";
					if(!detail.cert_show){
						signName = detail.sign_name.substr(0,1) + "**";
					}
	                break;
	            case 2:
	                icon = img_identification_c;
	                text = "企业";
	                break;
	            case 3:
	                icon = img_identification_o;
	                text = "组织";
	                break;
	            case 4:
	                icon = img_identification_g;
	                text = "公众号";
	                break;
	            default:
	                icon = null;
	                break
	        }
        }

        let summary = detail.summary;
        if(detail.auth >1 && detail.role){
	        if(!summary || summary.length<=0){
	        	summary = "Ta组织活动太忙，还没有腾空写简介"
	        }
        }
        
		let btnColor = {backgroundColor: '#00a8f3'};
		if(detail && detail.btn_config && detail.btn_config.color){
			btnColor.backgroundColor = detail.btn_config.color;
		}

        return (
        	<div className="Detail-role-panle">
            <div className="Detail-originator">
				<div className="originator-info-panel">
                <div className="originator-info" onClick={()=>{
						window._hmt.push(['_trackEvent', 'button_to_homepage','', '活动详情点击主页', '']); //百度统计
						React.$wx.jumpNewPath.call(this,'/h/'+detail.owner_unionid)
					}}>
	            	 <div className='detail-originator-left'>
					 {
	            	 	this.getHeader(detail)
	            	 }
	            	 <div className="originator-type">
	            	 	<h3 className="originator-top">				          
		            	 	 <span>{signName}</span>
	            	 	</h3>
						<div>
							{
								detail.role ? <div style={btnColor} className="auth-type-parent">
									<img className="auth-icon" src={ic_person_identity} />
									<label>{text}</label>
								</div> : null
							}
							<div className='send-message' onClick={(e)=>{
								e.stopPropagation()
								if(!Config.TOKEN){
									React.$wx.webLogin()
								}else if(!WxApi.isWeixn() && WxApi.isMobile() && !wxapi.isUnionPay()){
									this.getTicket(detail)
								}else{							
									this.props.history.push('/message')							
								}
							}}><i className="iconfont icon-xinxi"></i>&nbsp;留言</div>
						</div>
	            	 </div>
					 </div>
	                <button className="detail-originator-right">
						主页
						<img className="arrow-share" src={right_arrow} />
					</button>
            	</div>
				</div>
				  { detail.summary ? <div className='owner-desc'>{detail.summary}</div>  : '' }				 				   
            </div>
            {
            	this.getBtn(detail)
            }
            </div>
        )
    }

	// 消息订阅弹窗
	renderMsgDialog(detail){
		let signName = detail.sign_name;
		if(detail.auth == 1 && !detail.cert_show){
			let nameArr = detail.sign_name.toString();
			signName = nameArr[0] + '**';
		}
		return <NewMsgDialog ref="messageDom" signName={signName} owner_pic={detail.owner_pic || Config.defaultAvatar} sub_user={this.state.sub_user} 
		owner_unionid={detail.owner_unionid} onRef={(ref)=>{ this.child = ref}}/>
	}
	// 联合创作
	renderEnrollGroup(detail){
		let list = {					
			1:'共创',
			2:'嘉宾',
			3:'联名',
			4:'赞助',
			5:'联名'
		}
		return (
			<ul class="team-user-panel">
				<li className='team-list'>
					<div class="team-avatar">
						<img class="team-img" src={detail.owner_pic || Config.defaultAvatar} />
						<span class="team-tag">发起</span>	
						<img class="auth-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230705/872afef5e0be594e97fd9eaaf60f5fd1.png"></img> 
					</div>
					<span class="team-uname">{WxApi.getDefaultName(detail.sign_name)}</span>	
				</li>
				{
					detail.team_user.map((item,index)=>{
						return (
							<li class="team-list" key={index} onClick={()=>{
								React.$wx.jumpNewPath.call(this,'/h/'+item.unionid);
							}}>				
								<div class="team-avatar">
										<img class="team-img" src={item.upic || Config.defaultAvatar} />
										<span class="team-tag">{list[item.type]}</span>	
										{
											item.is_auth ? 
											<img class="auth-icon" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230705/872afef5e0be594e97fd9eaaf60f5fd1.png"></img> : ''
										}										
								</div>				
								<span class="team-uname">{item.uname}</span>											 			 
							</li>		
						)
					})
				}
			</ul>
		)
	}

    renderFastJoin(detail){
    	let _this = this;
		let _fast = JSON.parse(sessionStorage.getItem("fastArr"));
		if(detail.status == 1 && detail.fast_team == 1 && _fast && _fast.length > 0 && _fast[0].team_num > 1 && _fast[0].team_num > _fast[0].joins){
			
				return(<div className="fast-join-panel">
					<div className="fast-panel-title">快速成团</div>
				{
					
					_fast.map(function(item,index){
						if(item.team_num > item.joins && item.fail == 0){
							return (
				                <Flex key={index} className="Detail-list-item">
				                
				                    {
				                    	item.heads && item.heads.length ? item.heads.map(function(header,index){
				                    		if(index <5){
				                    			return (<img className="header-img" src={header||Config.defaultAvatar}/>)
				                    		}
				                    		
				                    	}) : <img className="header-img" src={Config.defaultAvatar}/>
				                    }
				                    <div className="Detail-list-flexpanel">
				                    	
				                    </div>
			                       <Flex.Item className="Detail-item-des mr0"  >
			                          {
			                          	item.has_join ? <div className={"group-info"} >
	                            <span>还差  <span className={"people-num"}>{item.team_num - item.joins}</span> 人成团</span>
	                            <Button inline  className={"team-btn"} onClick={()=>{ 
	                            	_this.props.enrollStore.setTeamId(item.tid)
									_this.props.myEnrollStore.setCurrentEnroll({info_id: item.info_id})  
	                            	_this.props.history.push('/groupCert')
	                            }}>查看团</Button>
	                        </div> :<div className={"group-info"}>
			                            <span>还差 <span className={"people-num"}>{item.team_num - item.joins}</span> 人成团</span>
			                            <Button inline  className={"team-btn join-btn"} onClick={()=>{ 
											if(Config.TOKEN){
												_this.props.enrollStore.getItemData().then(()=>{
													var enrollStore = _this.props.enrollStore;
													enrollStore.setTeamId(item.tid);
													_this.props.history.push('/enrollGroupForm')
												})												
											}else{
												React.$wx.webLogin();
											}			                            	
			                            }}>去参团</Button>
			                        </div>
			                          }
			                          
			                        </Flex.Item>
				                </Flex>
				            );
						}
						
						
					})
				}
				</div>)
			
			
			
		}
    	
    }
    
    renderPlay(){
    	return(
    		<div className="play-group-tips">
    			<h3 className="play-title">拼团玩法</h3>
    			<img src={img_pintuan_tips} />
    			<div className="play-step">
    				<div className="step-item">
    					<div>选择商品</div>
    					<div>开团拼团</div>
    				</div>
    				<div className="step-item">
    					<div>付款邀请</div>
    					<div>好友参团</div>
    				</div>
    				<div className="step-item">
    					<div>达到人数</div>
    					<div>拼团成功</div>
    				</div>
    				<div className="step-item">
    					<div>拼团失败</div>
    					<div>自动退款</div>
    				</div>
    			</div>
    		</div>
    	)
    	
    }
    
    showRoleMinProgram(type){
		this.setState({
	        roleMinProgram: type,
	    })
		
	}
    
    //查看小程序二维码认证
	renderMinProgramRole(){
		return(
			<Flex className="Detail-minProgram-panel" style={{display:this.state.roleMinProgram}}>
				<div className="share-choice-mask" onClick={() => { this.showRoleMinProgram('none')}}></div>
				<div className="minProgram-panel">					
					<i className="close-btn" onClick={() => { this.showRoleMinProgram('none')}}></i>
					<div className="panel-title">请去小程序完成</div>
					<div className="QRCode-img">
						<img src={'https://cdn-xcxcustom.weiyoubot.cn/20190516/18325c92c33d3cea608109cade0fb00c.jpeg'} />
					</div>
					<div className="min-tips">长按识别二维码，立即体验</div>					 					
				</div>
			</Flex>
		)
	}

	//获取描述内容
	getContent(detail){
		if(detail.content && detail.content !="无"){
       		return(<pre className="Detail-describe-img">{detail.content}</pre>)
       	}else{
       		return(<div className="nullData"></div>)
       	}
	}


    //输出描述
    renderDescribe(detail) {
        let enrollStore = this.props.enrollStore;
        return (
            <Flex className="Detail-describe group-detail-describe" direction={"column"}>
                <Flex.Item
                    className={"Detail-describe-content" + ((detail.content == "无" || enrollStore.describeShow) ? "" : " hide")}>
                    {
                   		Array.isArray(detail.content)?detail.content.map((item,index)=>{
							const style = {
								color: item.color || '#333',
								fontSize: item.font_size / 2 || 16 + 'px',
								textAlign: item.text_align || 'left'

							}
	                   			if(item.type == "text" && item.value){
									if(index == 0){
										let description = item.value.substring(0,100)
										document.getElementsByTagName('meta')[4].setAttribute("content",description)
									}
									return(<pre className="Detail-describe-img" key={index} style={style}>{item.value}</pre>)
								}else if(item.type == "img" || item.type == 'img_hd'){
									return(<div key={index} className="Detail-describe-img detail-img"><img src={item.value} /></div>)
								}
								else if (item.type == "file"){
									let colorType = 'bg-default';
									let fileList = {
										'W':'bg-blue',
										'doc':'bg-blue',
										'X':'bg-green',
										'pdf':'bg-red',
										'P':'bg-orange'
									}
									if(fileList[item.file_type]){
										colorType = fileList[item.file_type];
									}		
									let file_index = item.name.lastIndexOf('.');
                   					return(<div key={index} onClick={() => {
											if(item.download == 1){ //只可查看不可下载
												if((!WxApi.isMobile() || WxApi.isOtherEnvironments()) && !WxApi.isWeixn()){ //pc浏览器					
													this.showDetailQrCode('block');
												}else if(WxApi.isWeixn() && !Config.token){
													React.$wx.webLogin();
												}else if(!WxApi.isWeixn() && WxApi.isMobile() && !wxapi.isUnionPay()){//手机浏览器				
													this.getTicket(detail);
												}										
											}else{
												window.location.href = item.url;
											}			
                                         }} className="rule-file-item">
											<div className={["rule-file-type "+ colorType].join('')}>{item.file_type}</div>
											<div className="rule-file-name">{file_index > -1 ? item.name.substring(0,file_index) : item.name}</div>
											{
												item.download != 1 ? <img className='down-btn' src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230426/0632028fda6d1c479fab874370e9fa80.png"></img> : ''
											}
											{
												WxApi.isWeixn() && WxApi.isMobile() && item.download == 1 ?
												<wx-open-launch-weapp												
													id="jump-wx-btn"
													username="gh_ae6449501a82"
													path={detail.eid ? this.wxJumpPath : '/pages/default/default.html'}
													>																						
													<script type="text/wxtag-template">
														<div style={{width:'100%',height:'60px'}}></div>
													</script>																						
												</wx-open-launch-weapp> : ''
											}		
										  </div>)
								}else if(item.type == 'adress'){
									return (
										<div className='adress-box' onClick={()=>{
											if(WxApi.isMobile() && WxApi.isWeixn()){
												if(!Config.TOKEN){
													React.$wx.webLogin();
													return;
												}
												window.wx.openLocation({
													latitude: item.latitude, // 纬度，浮点数，范围为90 ~ -90
													longitude: item.longitude, // 经度，浮点数，范围为180 ~ -180。
													address: item.value, // 地址详情说明
													scale: 28, // 地图缩放级别,整形值,范围从1~28。默认为最大
												});
											}else{
												const linkElement = document.createElement('a');
												linkElement.target="_blank";
												linkElement.href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${item.latitude},${item.longitude};title:地址;addr:${item.value}&referer=myapp`
												// linkElement.href = `https://api.map.baidu.com/marker?location=${detail.latitude},${detail.longitude}&title=活动地址&content=${detail.address}&output=html&src=地图&ak=G1RjyBHcccDk6pb865C4FrPXjcpB2eKM`						
												const clickEvent = new MouseEvent('click', {
													view: window,
													bubbles: true,
													cancelable: true
												});							
												linkElement.dispatchEvent(clickEvent);
											}
										}}>
										   <img src={icon_adress} className="adress-img" />
										   <span> {item.value}</span>
										</div>
									)
								}else if(item.type == 'url'){
									return (
										<div key={index} className="article-box" onClick={()=>{
											window.location.href = item.link;
										}}>
											<img src={item.icon} className="article-img"></img>
											<p className="article-title">{item.value}</p>
										</div>
									)
								}else if(item.type == 'video'){
									return (
									   <video width="320" height="240" controls="controls" style={{display:'block',margin:'10px auto'}}>
										   <source src={item.value} type="video/mp4" />
									   </video>
									)
								}else if(item.type == 'copy'){
									return (
										<div className="applet-box flex-between-box" onClick={()=>{
											WxApi.copeText(item.value)
											Toast.success('复制成功')							
										}}>											 
											<img className="applet-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230220/bbc74473a300035bee8247e1037e4adb.png" />
											<div className="applet-content">												
												<p className="copy-desc">{item.desc}</p>															
												<span>{item.value}</span>																					
											</div>
											<Button type="primary" className="open-btn">复制</Button>
										</div>
									 )
								}else if(item.type == 'program'){
									const miniBtnStyle = {
										display:'inline-block',
										width:'70px',
										height:'28px',
										padding:'10px 0',																	
										position:'absolute',
										right: '10px',
										top:'7px',
										zIndex:"10"					
									}
									let defaultAppLog = "https://cdn-xcx-qunsou.weiyoubot.cn/material/20230220/4bb82882884598314f9ab20e78f55328.png";
								   	return (
										<div className="applet-box flex-between-box">  
											<img className="applet-img" src={item.logo||defaultAppLog} />                     											
											<div className="applet-content">
												<span>{item.value}</span>
												<p className="applet-desc">{item.desc}</p>
											</div>
											<Button type="primary" className="open-btn" onClick={()=>{
												if(item.mini_type > 0){
													window.open(item.path);
												}												
											}}>打开</Button>
											{
												item.mini_type == 0 ? 
												<wx-open-launch-weapp id="launch-btn" style={miniBtnStyle} username={item.originalId} path={item.path}>
													<script type="text/wxtag-template">														
														<span style={miniBtnStyle}></span>												
													</script>
												</wx-open-launch-weapp> : ''
											}																				
										</div>
									)
								}else if(item.type == 'channel'){
									return (
										<div className="channel-box">
											<img className="channel-img" src={icon_video_img}/>                      
											<span>{item.value}</span>
										</div>
									)
								}else if(item.type == 'audio'){
									return (
										<div className="audio-box">
											<div className="audio-main" onClick={()=>{
												 if(this.state.currAudioIndex == index){
													this.audioBox.pause();
													this.state.currAudioIndex = -1;
												}else{
													if(!this.audioBox){
														this.audioBox = new Audio();
													}
													this.audioBox.src = item.path;
													this.audioBox.addEventListener('ended',()=>{ //语音播放完毕
														this.setState({ currAudioIndex: -1 });                
													});
													this.audioBox.play();													 
													this.setState({ currAudioIndex: index });
												} 
											}}>     
												{
													index == this.state.currAudioIndex ?
													<img className="audio-play-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230706/46e95d5bad6311ee3baa23f62f144377.gif"></img>
													:
													<img className="audio-play-img" src="https://cdn-xcx-qunsou.weiyoubot.cn/material/20230706/2f2983dd7572c12401096261d73bea12.png"></img>
												}
												<span>{item.duration}″</span>
											</div>                        
										</div>  
									)
								}		 	                   			
	                   		}):this.getContent(detail)
                   }
                    
                </Flex.Item>
            </Flex>
        )
    }

	renderGroupButton(detail){
		if(detail.temp != 6)return
		let btnColor = {
			backgroundColor:"#00a8f3"
		}
		if(detail && detail.btn_config && detail.btn_config.color){
			btnColor.backgroundColor = detail.btn_config.color;
		}
		let { timeInfo } = this.state;
		if(Config.TOKEN){
			if(detail.status == 2 && !detail.info_id){
				return (
					<Button type="primary" style={btnColor} onClick={()=>{
						this.props.myEnrollStore.setCurrentEnroll({info_id: detail.info_id});
						this.props.history.push('/cert');
					}}>已截止</Button>
				)
			}else if(detail.status == 2 && detail.info_id) {
				return(
					<Button type="primary" style={btnColor} onClick={() => {
						this.props.myEnrollStore.setCurrentEnroll(0);
						if(detail.info_id){
							let params = {
								team_fee:detail.team_items.team_fee/100,
								fee:detail.team_items.fee/100,							
								sub_user_type:detail.sub_user_type,
								btn_config:detail.btn_config,
								btn_type: detail.btn_type,
							}
							sessionStorage.setItem('groupInfo',JSON.stringify(params))
							this.props.history.push('/group-orders')
						}
	                }}>查看/取消</Button>)
			}else if(detail.status == 0){
				return (
					<Button type="primary" style={btnColor} onClick={()=>{
						this.props.myEnrollStore.setCurrentEnroll({info_id: detail.info_id});
						this.props.history.push('/cert');
					}}>未开始</Button>
				)
			}else if(detail.status == 1 && detail.info_id && detail.on_behalf_limit != 0) {
				return(
					<Button type="primary" className='detail-submit-btn' style={btnColor} onClick={async () =>{		
						let params = {
							team_fee:detail.team_items.team_fee/100,
							fee:detail.team_items.fee/100,					
							sub_user_type:detail.sub_user_type,
							btn_config:detail.btn_config,
							btn_type:detail.btn_type,
						}
						sessionStorage.setItem('groupInfo',JSON.stringify(params));
						this.props.history.push('/group-orders');			
					} }>
						<span>新增/取消</span>
						{
							detail.btn_config && detail.btn_config.btn_cd == 1 ? 
							<span className="cen-countdown">剩余{timeInfo.hour}小时{timeInfo.minute}分钟{timeInfo.second >= 10 ? timeInfo.second : '0' + timeInfo.second}秒</span> : ''
						} 
					</Button>)	 
			}else {
				return (
					<div className="group-buy-btn">
						<Button type="primary" className="single-buy detail-submit-btn" style={btnColor} inline onClick={async ()=>{						
							this.props.myEnrollStore.setCurrentEnroll(0);
							await this.userNickname.checkUserName(); //判断是否有昵称
							if(detail.info_id){
								let params = {
									team_fee:detail.team_items.team_fee/100,
									fee:detail.team_items.fee/100,									
									sub_user_type:detail.sub_user_type,
									btn_config:detail.btn_config,
									btn_type:detail.btn_type,
								}
								sessionStorage.setItem('groupInfo',JSON.stringify(params))
								this.props.history.push('/group-orders')
							}else if(detail.sub_user_setting && detail.sub_user_setting.length && detail.sub_user_setting.indexOf('all') == -1 && detail.sub_user_setting.indexOf('white') == -1){						 
								if((detail.sub_user_setting.indexOf('vip') != -1 && this.state.vipList.length > 0) || (detail.sub_user_setting.indexOf('fans') != -1 && detail.sub_user)){
									if(detail.team_items.fee == 0){ //只要单购价格为0，那就只能团购
										this.props.enrollStore.getItemData().then(()=>{
											this.props.enrollStore.setTeamId(null)
											var enrollStore = this.props.enrollStore;
											enrollStore.setIsteamBuy(1);								 
											this.props.history.push('/enrollGroupForm');
										})		
									}else{
										await this.getEnrollStatus(detail);
										await this.childNotice.checkHasToken(); //检测是否有提交前设置
										this.setState({groupDialog:true})
									}	
								}else if(detail.sub_user_setting.indexOf('fans') != -1 && !detail.sub_user){
									Modal.alert('温馨提示','你还不是当前组织的粉丝，不能参加此活动，请先去关注活动组织者', [
										{ text: '取消', style: 'default'},
										{ text: '去关注', onPress: () => {	
											React.$wx.jumpNewPath.call(this,'/h/'+sessionStorage.getItem('owner_unionid'))
										} },
									])
								}else if(detail.sub_user_setting.indexOf('vip') != -1 && !this.state.vipList.length){
									Modal.alert('温馨提示','你还不是当前组织的会员，不能参加此活动，请先去申请成为会员', [
										{ text: '取消', style: 'default'},
										{ text: '去申请', onPress: () => {	
											React.$wx.jumpNewPath.call(this,'/apply-vip?cid='+detail.items_fee.vip_card_id)
										} },
									])
								}else if(detail.sub_user_setting.indexOf('end_all') != -1){
									Modal.alert('温馨提示','发起人设置了所有人不可提交，暂时无法提交', [
										{ text: '取消', style: 'default'},
										{ text: '去留言', onPress: () => {	
											React.$wx.jumpNewPath.call(this,'/message')
										} },
									])								 
								}else{
									if(detail.team_items.fee == 0){ //只要单购价格为0，那就只能团购
										this.props.enrollStore.getItemData().then(()=>{
											this.props.enrollStore.setTeamId(null)
											var enrollStore = this.props.enrollStore;
											enrollStore.setIsteamBuy(1);								 
											this.props.history.push('/enrollGroupForm');
										})		
									}else{
										await this.getEnrollStatus(detail);
										await this.childNotice.checkHasToken(); //检测是否有提交前设置
										this.setState({groupDialog:true})
									}	
								}						 
							}else if(detail.team_items.fee == 0){ //只要单购价格为0，那就只能团购
								this.props.enrollStore.getItemData().then(()=>{
									this.props.enrollStore.setTeamId(null)
									var enrollStore = this.props.enrollStore;
									enrollStore.setIsteamBuy(1);								 
									this.props.history.push('/enrollGroupForm');
								})		
							}else{
								await this.getEnrollStatus(detail);
								await this.childNotice.checkHasToken(); //检测是否有提交前设置
								this.setState({groupDialog:true})
							}							
						}}>
							<span>{detail.btn_config && detail.btn_config.text ? detail.btn_config.text : this.state.btnTypeText[detail.btn_type]}</span>
							{
								detail.btn_config && detail.btn_config.btn_cd == 1 ? 
								<span className="cen-countdown">剩余{timeInfo.hour}小时{timeInfo.minute}分钟{timeInfo.second}秒</span> : ''
							} 
						</Button>											
					</div>
				)				
			}
			
		}else {
			return (
				<Button type="primary" style={btnColor} onClick={()=>{
					this.showMinProgram()
				}}>{detail.btn_config && detail.btn_config.text ? detail.btn_config.text : this.state.btnTypeText[detail.btn_type]}</Button>	
			)	
		}
	}
	// 倒计时
	getTimeDown(detail){
		if(this.timeDown)clearInterval(this.timeDown);
		let endTime = detail.end_time * 1000;
		let nowTime = new Date().getTime();
		let time = endTime - nowTime;		
		if(time > 0){
			let hour = Math.floor(time / 1000 / 60 / 60);
			let minute = Math.floor(time / 1000 / 60 % 60);
			let second = Math.floor(time / 1000 % 60);
			this.setState({
				timeInfo: {
					hour,
					minute,
					second
				}
			})
			this.timeDown = setInterval(() => {				
				if(second > 0){
					second--;
				}else{
					second = 59;
					if(minute > 0){
						minute--;
					}else{
						minute = 59;
						if(hour > 0){
							hour--;
						}else{
							clearInterval(this.timeDown);
							detail.status = 2;
							this.props.enrollStore.isEnd = true;
							this.setState({hour: 0, minute: 0, second: 0, detail: detail});		
							return;					 
						}
					}
				}
				this.setState({
					timeInfo: {
						hour,
						minute,
						second
					}
				})
			}, 1000)
		}
	}
	// 判断发起人是否设置了所有人不可提交
	getEnrollStatus(detail){
		return new Promise((resolve,reject)=>{
			if(detail.sub_user_type == 'end_all'){
				Modal.alert('温馨提示','发起人设置了所有人不可提交，暂时不可提交', [
					{ text: '取消', style: 'default'},
					{ text: '去留言', onPress: () => {	
						this.props.history.push('/message') 
					} },
				])
				reject()
			}
			resolve()
		})		
	}

	renderGroupModal(detail){
		if(!detail||!detail.team_items)return
		return (
			<Modal visible={this.state.groupDialog} maskClosable={false} animationType="slide-up" popup  className='m-group-modal'>					
				<div className='flex-between-box'>
					<h4 className='m-group-title'>请选择购买方式</h4>
					<img className='m-close-img' src={icon_close_img} onClick={()=>{
						this.setState({groupDialog:false})
					}}/>
				</div>
				<ul className='m-group-list'>
					<li className='m-group-items' onClick={()=>{
						this.props.enrollStore.getItemData().then(()=>{
							this.props.enrollStore.setTeamId(null)
							var enrollStore = this.props.enrollStore;
							enrollStore.setIsteamBuy(1);
							this.setState({groupDialog:false});
							this.props.history.push('/enrollGroupForm');
						})						
					}}>
						<span className='m-buy-collage'>￥{detail.team_items.team_fee/100}拼团购买</span>
						<Button className='m-buy-btn' type='warning'>我要开团</Button>
					</li>				 
					<li className='m-group-items' onClick={()=>{
						this.props.enrollStore.getItemData().then(()=>{
							var enrollStore = this.props.enrollStore;
							enrollStore.setIsteamBuy(2);
							this.setState({groupDialog:false});
							this.props.history.push('/enrollGroupForm');
						})						
					}}>
						<span className='m-buy-alone'>￥{detail.team_items.fee/100}单独购买</span>
						<Button className='m-buy-btn' type='primary'>单独购买</Button>
					</li>						
				</ul>
			</Modal>
		)
	}


    shareImg(type) {
        this.props.enrollStore.getShare(type).then((d) => {
            if (d && d.url) {
                React.$wx.previewImage({
                    current: d.url, // 当前显示图片的http链接
                    urls: [d.url] // 需要预览的图片http链接列表
                });
            }
        });
    }
    
	handleTransfor(detail){
		if(detail.is_owner){
			Modal.alert('温馨提示','不能转载自己的活动',[{ text: '我知道了' }]);
			return;
		}
		const params = {
			eid: detail.eid,
			type: 'sub'
		}
		this.props.myEnrollStore.reshippedEnroll(params).then(res=>{
			if(res.sta == 0){
				this.showSharePanel('none');
				Modal.alert('温馨提示', '转载成功，请到你的个人主页查看', [					
					{ text: '取消', onPress: () => console.log('ok')},
					{ text: '前往主页查看', onPress: () => {
						React.$wx.jumpNewPath.call(this,'/h/'+detail.unionid);
					}},
				]);			
			}else{
				Toast.fail(res.msg);
			}
		})
	}
	
    showSharePanel(type){
		this.setState({
	        sharePanel: type,
	    })
	}
    
    //分享选择面板
	renderSharePanel(is_show,detail) {
			return(
				<Flex className="Detail-share-choice-panel" style={{display:this.state.sharePanel}}>
	    			<div className="share-choice-mask" onClick={() => { this.showSharePanel('none')}}></div>
	    			<div className="share-choice-panel">
	    				<div className="share-btn-panel">
	    					<div className="share-icon" onClick={() => { this.showFriend('block')}}>
	    						<i></i>
	    						<div>分享给好友</div>
	    					</div>
	    					<div className="share-icon poster-img" onClick={() => {this.shareImg(0,detail)}}>
	    						<i></i>
	    						<div>海报分享图</div>
	    					</div>
							<div className="share-icon wx-img" onClick={() => { this.shareImg(1,detail)}}>
	    						<i></i>
	    						<div>小程序码</div>
	    					</div>
	    					<div className="share-icon qrcode-img" onClick={() => {this.shareImg(4,detail)}}>
	    						<i></i>
	    						<div>二维码</div>
	    					</div>
							<div className="share-icon mobile-img" onClick={() => {																	 
								let _url = `https://p.baominggongju.com/group?eid=${detail.eid}`;
								if(detail.unionid){
									_url += `&referer=${detail.unionid}`;		
								}else if(Config.referer){
									_url += `&referer=${Config.referer}`;							
								}
								WxApi.copeText(_url);														
								Toast.success('链接复制成功')
								this.setState({sharePanel: 'none'})
							}}>
	    						<i></i>
	    						<div>H5链接</div>
	    					</div>
							<div className='share-icon command-img' onClick={()=>{								 
								this.props.myEnrollStore.getSearchToken().then(res=>{
									if(res.sta == 0){									 
										this.setState({
											enroll_token: res.data.search_token,
											sharePanel: 'none',
											showEnrollToken: true
										})
									}else{
										Toast.fail(res.msg);
									}
								})								
							}}>
								<i></i>
								<div>活动口令</div>
							</div>
							{/* {
								detail.is_owner != 1 ? 
								<div className="share-icon transfor-img" onClick={() => {this.handleTransfor(detail)}}>
									<i></i>
									<div>转载到主页</div>
								</div> : ''
							} */}
	    				</div>
	    				<div className="cancel-btn" onClick={() => { this.showSharePanel('none')}}>取消</div>
	    			</div>
	    		
	    		</Flex>
			)
	}
	
	
	showMinProgram(){
		React.$wx.webLogin()
	}

	showDetailQrCode(type){
		if(type == 'block'){
			React.$wx.openWeChat(this.wxJumpPath).then(()=>{
				let self = this;
				this.props.enrollStore.getShare(1).then((d) => {
					if(d && d.url) {
						self.setState({
							showDetailQrCode: type,
							detailQrCode: d.url,
						})
						this.props.myEnrollStore.setShareImg(d.url);
					}
				});
			})	
		}else{
			this.setState({
				showDetailQrCode: 'none'
			})
		}				
	}
	//查看小程序二维码
	renderDetailQrCode(){
		return(
			<Flex className="Detail-minProgram-panel" style={{display:this.state.showDetailQrCode}}>
				<div className="share-choice-mask" onClick={() => { this.showDetailQrCode('none')}}></div>
				<div className="minProgram-panel">
					<div className="new-share-minImg">
						<i className="close-btn" onClick={() => { this.showDetailQrCode('none')}}></i>
						<div className="panel-title">请去「报名工具」小程序查看</div>
						<div className="QRCode-img">
							<img src={this.state.detailQrCode} />
						</div>
						<div className="min-tips">长按识别二维码，立即体验</div>
					</div>					
				</div>
			</Flex>
		)
	}

	showHomePageQrCode(type, detail){
		let self = this;
		this.props.enrollStore.getHomePageShare(detail.owner_unionid).then((d) => {
			if(d && d.url) {
				self.setState({
					showHomePageQrCode: type,
					detailQrCode: d.url,
				})
			}
		});

	}
	//查看小程序二维码
	renderHomePageQrCode(detail){
		return(
			<Flex className="Detail-minProgram-panel" style={{display:this.state.showHomePageQrCode}}>
				<div className="share-choice-mask" onClick={() => { this.showHomePageQrCode('none', detail)}}></div>
				<div className="minProgram-panel">
					<i className="close-btn" onClick={() => { this.showHomePageQrCode('none', detail)}}></i>
					<div className="panel-title">长按识别二维码，查看Ta的主页</div>
					<div className="QRCode-img">
						<img src={this.state.detailQrCode} />
					</div>
				</div>
			</Flex>
		)
	}
	// 查看活动口令
	renderEnrollToken(){
		return(
			<Modal
			visible={this.state.showEnrollToken}
			closable={true}
			transparent			
			onClose={()=>{this.setState({showEnrollToken:false})}}
			title="分享当前活动"				
		 	>
			<div className='modal-address-panel'>
				<p className='token-title'>参与人打开小程序可以直接进入活动页面</p>
				<p className='token-content'>{this.state.enroll_token} 打开报名工具小程序首页搜索</p>
				<Button type="primary" className='copy-btn' onClick={()=>{
					WxApi.copeText(this.state.enroll_token + ' 打开报名工具小程序首页搜索');
					Toast.success('复制成功');
					this.setState({showEnrollToken:false});
				}}>复制口令</Button>
			</div>
		  </Modal>			 
		)
	}
	// 复制微信
	renderWxLink(detail){		 
		return (
			<Modal
			visible={this.state.showWxlink}
			closable={true}
			transparent			
			onClose={()=>{this.setState({showWxlink:false})}}
			title="微信号"				
		 	>
			<div className='modal-address-panel'>
				<p className='address-content'>{detail.wx_no}</p>
				<Button type="primary" className='copy-btn' onClick={()=>{
					WxApi.copeText(detail.wx_no);
					Toast.success('复制成功');
					this.setState({showWxlink:false});
				}}>一键复制</Button>
			</div>
		  </Modal>
		)
	}

	// 有争议的活动
	renderCensorModal(){
		return (			 
			<Modal
			popup
			visible={this.state.censorModal}
			onClose={()=>{this.setState({censorModal: false })}}
			animationType="slide-up"	
			className='modal-censor-panel'						
			>
				<div className='main-container'>
					<div>
						<img className="warn-img" src="https://gw.alipayobjects.com/zos/rmsportal/GIyMDJnuqmcqPLpHCSkj.svg" />
						<h4 className='warn-title'>活动存在有争议的内容</h4>
						<span>请耐心等待协商解决</span>
					</div>
					<div className="footer-panel">
						<Button className='cen-button continue-btn' onClick={()=>{
							this.setState({ censorModal: false });
						}}>继续访问</Button>
						<Button className='cen-button cancel-btn' type="primary" onClick={()=>{
							this.setState({ censorModal: false });
							this.props.history.push('/manage/myEnroll');
						}}>取消</Button>
					</div>
				</div>					
			</Modal>			 
		)
	}

	showFriend(type){
		this.setState({
	        shareFriend: type,
	        shareMinProgram: 'none',
	        sharePanel: 'none',
	    })
	}
	renderShareTips(){
		return(
			<Flex className="Detail-share-tips-panel" style={{display:this.state.shareFriend}}>
				<div className="share-choice-mask" onClick={() => { this.showFriend('none')}}></div>
				<div className="tips-content" onClick={() => { this.showFriend('none')}}>
					<img className="arrow-share" src={img_mask_arrow} />
					<div className="share-text">
						<span>点击右上角“...”</span><br/>
						<span>选择“发送给朋友”或者“分享到朋友圈”</span><br/>
						<span>即可完成分享</span>
					</div>
				</div>
			</Flex>
		)
	}
	
    
    
	showFriend(type){
		this.setState({
	        shareFriend: type,
	         sharePanel: 'none',
	    })
	}

    render() {
        let {detail, isEnd,isStart} = this.props.enrollStore;	 
		let _fast = JSON.parse(sessionStorage.getItem("fastArr"));
		let miniBtn = {
			flex:'1',
			height:'40px',
			backgroundColor:"#fff",
			border:'1px solid #ccc',
			cursor:'point',
			borderRadius:'5px',
		}
        if (!detail.title) {
			return(
				<div>
					{this.state.hasActive?
						(<div className="Detail-init-render-result">
							<div className="title">数据加载中</div>
							<div className="message">请稍后...</div>
						</div>
					):
					(
						<div className="Detail-init-render-result">
							<img className="cen-image" src="https://cdn-xcxcustom.weiyoubot.cn/20210526/f6cf229bd87b77f3c7f0e5d6b855ea4d.png"></img>
							<div className="cen-title">活动不存在</div>
							<span className="cen-warn">可能已经被删除</span>
							<div className='back-home-btn'>
								<button style={miniBtn} onClick={()=>{
									this.props.history.push('/manage/createEnroll')
								}}>返回首页</button>
								<button className='my-record' onClick={()=>{
									window._hmt.push(['_trackPageview',  '/manage/myEnroll'])
									this.props.history.push('/manage/myEnroll')
								}}>我的记录</button>
							</div>
						</div>
					)
				}	
                </div>
			)
        }
				
        return (
        
            <div className="btn_bottom" style={{paddingTop: detail && detail.user_style != 2 ? '50px' : '0'}}>
                   
				{
                  	detail.user_style != 2 ? this.renderHeader(detail) : ''
                }
				{
					this.renderWxCode(detail)
				}
                {
                    !this.state.isVertical ? this.renderBanner(detail) : null
                }
                {
                    !this.state.isVertical ? this.renderContent1(detail) : null
                }
				{
					this.state.isVertical ?
					<div className='vertical-screen'>
						{
							this.renderBanner(detail)
						}
						{
							this.renderContent1(detail)
						}						
					</div> : null
				}						 	 				
                {
                    detail.user_style != 2 && !detail.co_create ? this.renderOwner(detail) : ''
                }
				{
					this.renderMsgDialog(detail)
				}
				{
                    detail.user_style != 2 && detail.co_create == 1 ? this.renderEnrollGroup(detail) : ''
                }
				<SubmitNotice ref="submitNoticeDom" onRef={(ref)=>{ this.childNotice = ref}} detail={detail} onSuccess={()=>{
					if(!detail.team_items.fee){
						this.props.enrollStore.getItemData().then(()=>{
							this.props.enrollStore.setTeamId(null);
							var enrollStore = this.props.enrollStore;
							enrollStore.setIsteamBuy(1);
							this.setState({groupDialog:false});
							this.props.history.push('/enrollGroupForm');
						})								
					}else{
						this.setState({groupDialog:true});
					}						
				}}></SubmitNotice>
                {
                    !!detail.group_qr ? <List className="qun-view">
                        <Item
                            arrow="horizontal"
                            thumb={
                                <Badge text={1}>
                                    <img style={{width: 30, height: 30}} src={img_qun}/>
                                </Badge>
                            }
                            multipleLine
                            onClick={() => {
                                React.$wx.previewImage({
                                    current: detail.group_qr, // 当前显示图片的http链接
                                    urls: [detail.group_qr] // 需要预览的图片http链接列表
                                });
                            }}
                            extra={<Button inline={"true"} size="small">加入</Button>}
                        >
                            官方交流群 <Brief>邀请你进群参加交流讨论</Brief>
                        </Item>
                    </List> : null
                }
                {
            		this.renderFastJoin(detail)
                }
				{
					this.renderPlay()
				}
                {
                    this.renderDescribe(detail)
                }
                {
                	this.renderSharePanel('none',detail)
                }
                {
                	this.renderDetailQrCode()
                }
                {
                	this.renderHomePageQrCode(detail)
                }
				{
					this.renderWxLink(detail)
				}
				{
					this.renderEnrollToken()
				}
				{
					detail.censor_status == 3 ? this.renderCensorModal(detail) : null
				}
                {
                	this.renderShareTips()
                }
                {
                	this.renderMinProgramRole()
                }
				{
					this.renderGroupModal(detail)
				}         
                {
                    (detail.user_visible == 1 || detail.user_visible == 2) || detail.is_owner || detail.is_admin ?
                        <UserList eid={this.props.match.params.eid} themColor={this.state.themColor} openMsg={()=>{this.child.setDialog('open')}}/> : null
                }
				{
					this.renderNotice(detail)
				}
				{
					detail.sub_user_setting && detail.sub_user_setting.indexOf('group_enter') != -1 && (detail.is_admin || detail.is_owner) ? this.renderWxGroup(detail) : null
				}
				{
					!this.state.isWeixin && !Config.TOKEN  ? <div className="fix_bottom_weixin" onClick={() => { 
						if (this.state.isMobile && WxApi.isOtherEnvironments()){ //如果是在支付宝内 or 云闪付置浏览器中打开
							this.props.history.push('/login')
						}else if(this.state.isMobile){
							this.getTicket(detail)
						}else{
							this.showMinProgram()
						}
				}}></div> : null
				}
                <div className="fix_bottom">
                	<div className="share-panel">
						{
							detail.is_admin || detail.is_owner ? null :
							<div className="create btn" onClick={() => {
								window._hmt.push(['_trackEvent', 'button_to_homepage','', '活动详情底部菜单点击主页', '']); //百度统计
								React.$wx.jumpNewPath.call(this,'/h/'+detail.owner_unionid)
							}}>
								<i className="create-icon icon-style"></i>
								<div>主页</div>
							</div>
						}                    	
                    	<div className="share btn" onClick={() => { 
							if((detail.share == 2 || detail.share == 3) && (!detail.is_owner && !detail.is_admin)){
								Toast.fail("发起者设置了不允许他人分享", 2);
								return;
							}else if(detail.share == 1 && !detail.info_id){
								Toast.fail("发起者设置了仅参与者才能分享", 2);
								return;
							}else if(detail.sub_user_setting && detail.sub_user_setting.indexOf('group_enter') != -1 && !(detail.is_owner || detail.is_admin)){
								Toast.fail("发起人设置了“指定群可参与”时，不支持分享活动", 2);
								return;
							}
							this.showSharePanel('block')							 
						}
						}>
                    		<i className="share-icon icon-style"></i>
                    		<div>分享</div>
                    	</div>
						<div className="message-box-panel btn">
							<div className="share btn message-btn" onClick={(e) => {
									e.stopPropagation()
									if(!Config.TOKEN){
										React.$wx.webLogin()
									}else if(!WxApi.isWeixn() && WxApi.isMobile() && !wxapi.isUnionPay()){
										this.getTicket(detail)
									}else{							
										this.props.history.push('/message')							
									}									 			
								}}>
								<div style={{position:'relative', width: '20px', margin: '0 auto'}}>
									<i className="message-icon icon-style"></i>
									{
										(detail.is_owner || detail.is_admin) && detail.msg_count > 0 ? <span className='share-num'>{detail.msg_count > 999 ? '99+' : detail.msg_count}</span> : null
									}							
								</div>
								<div>{detail.is_owner || detail.is_admin ? '消息' : '留言'}</div>
							</div>
						</div>						
                    	{
							detail.is_owner || detail.is_admin ?
							<button className="create btn" onClick={()=>{
								this.setState({showManage: true})
							}}>
								<img className='manage-icon' src='https://cdn-xcx-qunsou.weiyoubot.cn/material/20230131/c996d91ae7afccb3e26b7b2f487728a1.png' />
								<div>管理</div>
							</button> : null
						}
                    </div>
                    {
                        this.renderGroupButton(detail)
                    }					
					{
						detail.is_owner || detail.is_admin ? <ManageDialog showManage={this.state.showManage} wxJumpPath={this.wxJumpPath} open={()=>{
							this.setState({showManage: false});
							this.showDetailQrCode('block');							
						}} close={()=>{
							this.setState({showManage: false});
						}}></ManageDialog> : null
					}			
					<SetUserInfo ref="userDom" needLogin={detail.need_login} onRef={(ref)=>{ this.userNickname = ref}}></SetUserInfo>					
                </div>
            </div>
        );
    }
}

export default App;
